import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { apiPost } from "../api/apiFunction";
import loadingGif from "../assets/videos/animationLoading.gif";

const PrivateRoute = () => {
  const token = localStorage.getItem("token");
  const refreshToken = localStorage.getItem("refreshToken")
  const expirationTime = localStorage.getItem("expirationTime");
  const rememberMe = localStorage.getItem("rememberMe");
  const userRole = localStorage.getItem("role");

  const location = useLocation();
  const navigate = useNavigate();
  const [isAuthorized, setIsAuthorized] = useState(null);

  useEffect(() => {
    const checkAccess = async () => {
      try {
        if (!token || (expirationTime && Date.now() > parseInt(expirationTime, 10))) {
          localStorage.clear();
          navigate("/login", { replace: true });
          return;
        }
        const response = await apiPost("panel/cek-hakakses", { url: location.pathname }, true, false);
        if (response.status === 200) {
          setIsAuthorized(true);
        } else {
          setIsAuthorized(false);
          navigate("/unauthorized", { replace: true });
        }
      } catch (error) {
        console.error("Error checking access:", error);
        setIsAuthorized(false);
        navigate("/unauthorized", { replace: true });
      }
    };

    checkAccess();
  }, [location.pathname, navigate, token, expirationTime, userRole]);

  if (isAuthorized === null) {
    return (
      <div className="flex justify-center items-center h-screen bg-black bg-opacity-10 w-full">
        <img src={loadingGif} alt="Loading Gif" />
      </div>
    );
  }  
  
  return <Outlet />;
};

export default PrivateRoute;
