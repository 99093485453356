import React, { createContext, useState } from "react";

export const HeaderTitleContext = createContext();

export const HeaderTitleProvider = ({ children }) => {
  const [title, setTitle] = useState("MBG");
  const [role, setRole] = useState("administrator");

  return (
    <HeaderTitleContext.Provider value={{ title, setTitle, role, setRole }}>
      {children}
    </HeaderTitleContext.Provider>
  );
};
