import React, { useState, useEffect, startTransition } from "react";
import { useNavigate } from "react-router-dom";

const SessionChecker = () => {
    const [isPopupVisible, setPopupVisible] = useState(false);
    const navigate = useNavigate()

    useEffect(() => {
        const expirationTime = localStorage.getItem("expirationTime");

        const checkSession = () => {
            if (expirationTime && Date.now() > parseInt(expirationTime, 10)) {
                setPopupVisible(true); // Tampilkan popup saat sesi habis
            }
        };

        const interval = setInterval(checkSession, 1000); // Cek setiap 1 detik

        return () => clearInterval(interval); // Bersihkan interval
    }, []);

    const handleLogout = () => {
        localStorage.clear();
        startTransition(() => {
            navigate("/login") // Redirect ke halaman login
        })
    };

    return (
        <>
            {isPopupVisible && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-96 text-center">
                        <p className="text-lg font-semibold mb-4">Sesi Anda Sudah Habis</p>
                        <p className="mb-6">Silakan login kembali untuk melanjutkan.</p>
                        <button
                            onClick={handleLogout}
                            className="px-6 py-2 bg-red-500 text-white rounded-md hover:bg-red-700 transition duration-300"
                        >
                            Logout
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

export default SessionChecker;
