import React, { useContext, useState, useEffect, startTransition } from "react";
import notification from "../assets/icons/notification.png";
import profilePicture from "../assets/images/profilePicture.png";
import { IoIosArrowDown } from "react-icons/io";
import { GoSearch } from "react-icons/go";
import { HeaderTitleContext } from "./headerTitle";
import { useNavigate, useLocation } from "react-router-dom";
import { SettingIcon } from "../assets/icons/icon";

function Header({ onSearch, placeholder  }) {
  const { title } = useContext(HeaderTitleContext);
  const [isLogoutPopupVisible, setIsLogoutPopupVisible] = useState(false);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');

  const location = useLocation();

  const [userData, setUserData] = useState({
    username: "",
    email: "",
    role: "",
    userId: "",
    profile: ""
  });

  useEffect(() => {
    setSearchTerm("");
  }, [location.pathname]);

  useEffect(() => {
    const username = localStorage.getItem("username") || "Ahmand Mambaus"; // Hardcoded jika tidak ada
    const email = localStorage.getItem("email") || "ahmad@gmail.com"; // Hardcoded jika tidak ada
    const role = localStorage.getItem("role") || "Direktur"; // Hardcoded jika tidak ada
    const userId = localStorage.getItem("userId") || "#1"; // Hardcoded jika tidak ada
    const profile = localStorage.getItem("profile");

    setUserData({ username, email, role, userId, profile });
  }, []);

  const isLoginPage = location.pathname === "/login";

  if (isLoginPage) {
    return null;
  }

  const toggleLogoutPopup = () => {
    setIsLogoutPopupVisible(!isLogoutPopupVisible);
  };

  const handleLogout = () => {
    // Remove the token from localStorage
    localStorage.removeItem("token");

    // Wrap the state changes inside startTransition
    startTransition(() => {
      // Navigate to login page
      navigate("/login");

      // Hide the logout popup
      setIsLogoutPopupVisible(false);
    });
  };

  const handleProfile = () => {
    navigate("/profile");
    setIsLogoutPopupVisible(false)
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    onSearch(e.target.value);
  };

  return (
    <div className="bg-[#F7F7F7] border-b border-[#C7CED2] fixed top-0 left-0 right-0 h-[72px] flex justify-between items-center py-3 pl-6 pr-4 gap-6 lg:left-[280px] lg:w-[calc(100%-280px)] z-10">
      <div>
        <div className="text-[#4A4D4F] capitalize md:ml-8 lg:ml-0 text-[18px] leading-6 font-semibold">
          {title}
        </div>
      </div>
      <div className="flex items-center gap-6">
        <div className="relative w-full max-w-[350px]">
          <input
            className="rounded-lg border outline-none text-[12px] border-[#B5B6B7] py-2 px-10 w-full h-[40px]" // Ubah padding kiri untuk ikon
            placeholder={placeholder}
            type="text"
            value={searchTerm}
            onChange={handleSearch}
          />
          <GoSearch
            size={20}
            className="absolute left-3 top-1/2 transform -translate-y-1/2 text-[#B5B6B7]"
          />
        </div>
        <div
          className="flex items-center gap-6 pr-8 cursor-pointer"
          onClick={handleProfile}
        >
          {/* <img src={notification} alt="notification" className="w-6 h-6" /> */}
          <div className="flex items-center relative">
            <img
              src={userData.profile}
              alt="avatar"
              className="w-8 h-8 rounded-full object-cover cursor-pointer"
            />
            <div className="ml-2 flex pr-8 items-center">
              <div>
                <div className="text-[#696B6D] whitespace-nowrap capitalize text-[16px] lg:w-[136px] lg:h-4 leading-4 font-semibold">
                  {userData.username}
                </div>
                <div className="capitalize mt-1 text-[14px] leading-4 font-normal text-[#696B6D]">
                  {userData.role}
                </div>
              </div>
              <IoIosArrowDown
                className="text-black ml-3 cursor-pointer"
                size={16}
              />
            </div>

            {/* {isLogoutPopupVisible && (
              <div
                className="absolute top-12 right-0 bg-white border border-[#C7CED2] rounded-md shadow-lg w-40 z-50"
                onClick={(e) => e.stopPropagation()}
              >
                <div className="p-2 flex flex-col gap-2">
                  <button
                    className="text-[#4A4D4F] text-[14px] hover:text-white hover:bg-custom-gradient py-1 rounded-lg"
                    onClick={handleLogout}
                  >
                    Keluar
                  </button>
                  <button
                    className="text-[#4A4D4F] text-[14px] hover:text-white hover:bg-custom-gradient py-1 rounded-lg"
                    onClick={handleProfile}
                  >
                    Profil
                  </button>
                  <button
                    className="text-[#696B6D] text-[14px] hover:text-white hover:bg-custom-gradient py-1 rounded-lg"
                    onClick={toggleLogoutPopup}
                  >
                    Kembali
                  </button>
                </div>
              </div>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
