import instance from "./axios";

export const configAuth = () => {
  return {
    Authorization: `${localStorage.getItem("token")}`,
    Accept: "application/json",
    "Content-Type": "application/json",
    Environment: "staging",
    // "x-header-deviceid": localStorage.getItem("deviceId"),
  };
};

export const config = () => {
  return {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
};

const getRefreshToken = () => localStorage.getItem("refreshToken");
let isRefreshing = false;

export const apiPost = (url, body, isAuth = true, isIconUpdate = false) => {
  let headers;
  if (isAuth === false) headers = config();
  if (isAuth === true) headers = configAuth();

  if (isIconUpdate) {
    delete headers["Content-Type"];
  }

  const response = instance
    .post(url, body, {
      headers: headers,
    })
    .then((response) => response)
    .catch((error) => {
      return handleApiError(error);
    });
  return response;
};

export const apiPut = (url, body, isAuth = true, isIconUpdate = false) => {
  let headers;

  if (isAuth === false) {
    headers = config();
  } else {
    headers = configAuth();
  }

  if (isIconUpdate) {
    delete headers["Content-Type"];
  }

  const response = instance
    .put(url, body, {
      headers: headers,
    })
    .then((response) => response)
    .catch((error) => {
      return handleApiError(error);
    });

  return response;
};

export const apiGet = (url, data, isAuth = true) => {
  let headers;
  if (isAuth === false) headers = config();
  if (isAuth === true) headers = configAuth();

  const response = instance
    .get(url, { params: data, headers: headers })
    .then((response) => response)
    .catch((error) => {
      return handleApiError(error);
    });

  return response;
};

export const apiDeletes = (url, isAuth = true) => {
  let headers;
  if (isAuth === false) headers = config();
  if (isAuth === true) headers = configAuth();

  const response = instance
    .delete(url, {
      headers: headers,
    })
    .then((response) => response)
    .catch((error) => {
      return handleApiError(error);
    });
  return response;
};
const refreshTokenAutomatically = () => {
  setInterval(async () => {
    const refreshtoken = getRefreshToken();
    if (refreshtoken && !isRefreshing) {
      isRefreshing = true;
      try {
        alert('function refresh token dijalankan')
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/user/token`,
          { refreshToken: "refreshToken" }, 
          {
            headers: {
              refreshtoken,
            },
          },
        );

        const { token: newtoken } = response.data;

        setToken(newtoken);

        isRefreshing = false;
      } catch (refreshError) {
        console.error('Gagal refresh token otomatis:', refreshError);
        isRefreshing = false;
      }
    } else {
      alert('function tidak dijalankan karena refreshtoken && !isRefreshing tidak ada')
    }
  }, 3600000);
};

refreshTokenAutomatically();