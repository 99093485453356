import React, { Suspense, useState, useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Sidebar from '../components/sidebar';
import Header from '../components/header'
import ErrorBoundary from '../components/error-boundary';
import SessionChecker from "../components/sessionChecker";
import { match } from "path-to-regexp";

function MainLayout() {
  const [searchTerm, setSearchTerm] = useState('');
  const location = useLocation();
  const navigate = useNavigate()

  // Mapping path ke placeholder
  const placeholderMapping = {
    "/": "Cari di Dashboard...",
    "/nutrition-dashboard/national-data": "Cari di Data Nasional...",
    "/nutrition-dashboard/nutrition-data": "Cari di Data Gizi...",
    "/nutrition-dashboard/nutritional-development": "Cari di Perkembangan Gizi...",
    "/nutrition-dashboard/nutrition-figures": "Cari di Angka Gizi...",
    "/fitur-transaksi": "Cari di Fitur Transaksi...",
    "/control-user": "Cari di Kontrol Pengguna...",
    "/distributor-feature": "Cari di Fitur Kurir...",
    "/product": "Cari di Produk...",
    "/main-category": "Cari di Kategori Utama...",
    "/sub-category": "Cari di Sub Kategori...",
    "/specific-category": "Cari di Spesifik Kategori...",
    "/invoice/non-subsidy": "Cari di Faktur Non-subsidi...",
    "/invoice/subsidy": "Cari di Faktur Subsidi...",
    "/schools": "Cari di Sekolah...",
    "/tariff": "Cari di Tarif...",
    "/company-profile/mbg": "Cari Profil Perusahaan...",
    "/events": "Cari di Acara...",
    "/processed-food/processed-food": "Cari di Makanan Olahan...",
    "/processed-food/processed-ingredienty": "Cari di Bahan Olahan...",
    "/incomplete-order": "Cari di Tinjau Pesanan...",
    "/delete-account": "Cari di Penjauan Hapus Akun...",
    "/help-center/main-title": "Cari di Judul Utama...",
    "/help-center/subtitle": "Cari di Sub Judul...",
    "/help-center/specific-title": "Cari di Spesifik Judul...",
    "/profile": "Cari Profil...",
    "/help-center/specific-help-center/consumer/:id": "Cari di Konsumen...",
    "/help-center/specific-help-center/vendors/:id": "Cari di Vendor...",
    "/help-center/specific-help-center/distributors/:id": "Cari di Distributor...",
    "/help-center/specific-help-center/suppliers/:id": "Cari di Supplier...",
    "/order-list": "Cari di Pesanan...",
    "/transaction-list": "Cari di Transaksi...",
    "/dashboard-admin": "Cari di Dashboard Admin...",
    "/transaction-print": "Cari di Transaksi"
  };

  // Cari placeholder berdasarkan path
  const getPlaceholder = () => {
    for (const [path, placeholder] of Object.entries(placeholderMapping)) {
      const isMatch = match(path, { decode: decodeURIComponent })(location.pathname);
      if (isMatch) {
        return placeholder;
      }
    }
    return "Cari sesuatu di sini...";
  };

  // useEffect(() => {
  //   const token = localStorage.getItem("token");
  //   const expirationTime = localStorage.getItem("expirationTime");
  //   const role = localStorage.getItem("role");
  
  //   if (!token || Date.now() > parseInt(expirationTime, 10)) {
  //     navigate("/login", { replace: true });
  //   } else if (role === "super admin" || role === "administrator") {
  //     navigate("/dashboard-admin", { replace: true });
  //   } else {
  //     navigate("/", { replace: true });
  //   }
  // }, [navigate]);

  return (
    <div className="relative font-work-sans">
      <SessionChecker />
      <Sidebar />
      <Header
        onSearch={setSearchTerm}
        placeholder={getPlaceholder()}
      />
      <Suspense fallback={<div>Loading...</div>}>
        <ErrorBoundary>
          <Outlet context={{ searchTerm }} />
        </ErrorBoundary>
      </Suspense>
    </div>
  );
}

export default MainLayout;